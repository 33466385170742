import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailEditorComponent } from 'angular-email-editor';
import { Global } from 'asap-crud';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { DataService } from '../@core/services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DataService]
})
export class HomeComponent implements OnInit {
  public id: number;
  public item: any;
  public trailStageSelected;
  public customData: any;
  public componentArray: Array<any> = [];
  public itemSelected: any;
  public status: string;
  public componentLoading: boolean;
  public notificationType;
  public notification;
  public design;
  public options = {
    locale: 'pt-BR',
    appearance: {
      theme: 'light',
      panels: {
        tools: {
          dock: 'left'
        }
      }
    }
  }
  public config: any;
  public style: string;
  @ViewChild(EmailEditorComponent, { static: false })
  private emailEditor: EmailEditorComponent;

  constructor(
    public service: DataService,
    public route: ActivatedRoute,
    public iziToast: Ng2IzitoastService
  ) {

    // this.editorLoaded();

    this.style = 'config';
    this.config = {};
    this.route.params.subscribe(params => {
      this.config.type = this.notificationType;
      sessionStorage.setItem('notification_type', this.notificationType);
      if (params.id) {
        this.id = params.id;
        this.getData();
      }
    });
  }

  title = 'angular-email-editor';

  editorLoaded($event = {}) {
    console.log('Editor Loaded ->', $event);
    // this.emailEditor.options.appearance = {
    //   appearance: {

    //   },
    //   features: {
    //     userUploads: false
    //   }
    // };
  }

  exportHtml() {
    this.emailEditor.editor.exportHtml((data) => console.log('exportHtml', data));
  }

  ngOnInit(): void {
    this.getData();
  }

  save(item = {}) {
    this.emailEditor.saveDesign(data => {
      console.log('Design', data);
      this.design = data;
    })
    this.emailEditor.editor.exportHtml((htmlData) => {
      let normalization = {};
      normalization = this.config;
      normalization = {
        title: this.config.title,
        description: this.config.description,
        from_name: this.config.from_name,
        last_access: (this.config.last_access) ? this.config.last_access.toString() : null,
        medias: this.config.medias,
        value: this.design,
        body: htmlData.html,
        type: this.notificationType,
        user_group_id: (this.config.user_group_id) ? this.config.user_group_id : null,
        frequency: (this.config.frequency) ? this.config.frequency : null,
      }

      if (this.config.id) {
        this.service.updateNotificationData(normalization, this.config.id).subscribe(response => {
          console.log('Response =>', response);
          if (response) {
            this.iziToast.show({
              title: 'Pronto',
              message: 'Configurações salvas com sucesso'
            });
            this.getData();
          }
        });
      }
      else {
        this.service.saveNotificationData(normalization).subscribe(response => {
          console.log('Response =>', response);
          this.iziToast.show({
            title: 'Pronto',
            message: 'Configurações salvas com sucesso'
          });
        });
      }
    });

    // console.log(htmlData);
    // console.log(this.config);
    // console.log(this.componentArray);

  }

  getDefaultTemplate() {
    this.service.getNotificationTemplate(this.notificationType).subscribe(response => {
      if (response && response.data) {
        this.design = response.data.value;
        console.log('Design ->', this.design);
        this.emailEditor.loadDesign(this.design);
      }
    })
  }

  getData() {
    this.service.notificationByType(this.notificationType).subscribe(response => {
      console.log('Response ->', response);
      if (response.data) {
        if (!response.data.value || response.data.value == '' || response.data.value == null) {
          this.getDefaultTemplate();
        }
        else {
          this.design = (response.data.value != null) ? response.data.value : null;
        }
        this.config = response.data;
        this.emailEditor.loadDesign(this.design);
        this.editorLoaded();
      }
      else {
        this.getDefaultTemplate();
      }
    })
  }

}
