import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalConfigurationsComponent } from './modal-configurations/modal-configurations.component';
import { ModalEmailMarketingComponent } from './modal-email-marketing/modal-email-marketing.component';
import { ModalEnvioTestesComponent } from './modal-envio-testes/modal-envio-testes.component';

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html'
})

export class TopMenuComponent {
    @Output() public sendEvent = new EventEmitter<any>();
    @Input() notificationType;
    @Input() entity;

    constructor(
        public dialog: MatDialog
    ) { }

    ngOnInit(): void {
        const isOpenEditor = sessionStorage.getItem('is_open_editor');
        this.entity.type = this.notificationType;

        if (!isOpenEditor) {
            this.openDialog();
            sessionStorage.setItem('is_open_editor', '1');
        }

        console.log('[TopMenuComponent]', this.entity);
    }

    public save() {
        this.sendEvent.emit(true);
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(ModalConfigurationsComponent, {
            width: '700px',
            data: this.entity
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.sendEvent.emit(this.entity);
            }
        });
    }

    openTestMailDialog() {
        const dialogRef = this.dialog.open(ModalEnvioTestesComponent, {
            width: '500px',
            data: this.entity
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                // this.sendEvent.emit(this.entity);

            }
        });
    }

    openEmailMarketingDialog() {
        const dialogRef = this.dialog.open(ModalEmailMarketingComponent, {
            width: '500px',
            data: this.entity
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                // this.sendEvent.emit(this.entity);

            }
        });
    }

    exit() {
        this.sendEvent.emit(this.entity);
        window.close();
    }
}
