import { Component, Inject, OnInit } from '@angular/core';
import { MatChipInputEvent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-modal-envio-testes',
  templateUrl: './modal-envio-testes.component.html',
  styleUrls: ['./modal-envio-testes.component.scss']
})
export class ModalEnvioTestesComponent implements OnInit {

  public trails;
  constructor(
    public dialogRef: MatDialogRef<ModalEnvioTestesComponent>,
    @Inject(MAT_DIALOG_DATA) public entity,
    public service: DataService,
    public iziToast: Ng2IzitoastService
  ) { }

  ngOnInit() {
    console.log('[ModalEnvioTestesComponent] ->', this.entity);
    if (this.entity.type === 'COURSE_CONTINUATION') {
      this.getAvaibleTrails();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    console.log(this.entity);
    this.service.saveNotificationTest(this.entity).subscribe(response => {
      this.dialogRef.close();
      this.iziToast.show({
        title: 'Pronto',
        message: 'Você receberá os seus testes dentro de alguns segundos.',
      })
    });
  }

  getAvaibleTrails() {
    this.service.getAvaibleTrails().subscribe(response => {
      this.trails = response;
    })
  }

}
