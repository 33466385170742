import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataService {
  constructor(public http: HttpClient) {
  }

  notificationByType(type: string): Observable<any> {
    return this.http.get(environment.baseUrl + `/api/v2/admin/notification-templates/type/${type}`, this.header);
  }

  getNotificationTemplate(type: string): Observable<any> {
    return this.http.get(environment.baseUrl + `/api/v2/admin/notification-templates/default/${type}`, this.header);
  }

  saveNotificationData(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + `/api/v2/admin/notification-templates`, data, this.header);
  }

  getAvaibleTrails(): Observable<any> {
    return this.http.get(environment.baseUrl + `/api/v2/admin/notification/get_trails`, this.header);
  }

  updateNotificationData(data: any, id: any): Observable<any> {
    return this.http.put(environment.baseUrl + `/api/v2/admin/notification-templates/${id}`, data, this.header);
  }

  saveNotificationTest(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + `/api/v2/admin/notification/save_teste`, data, this.header);
  }

  saveNotificationEmailMarketing(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + `/api/v2/admin/notification/email_marketing`, data, this.header);
  }

  getGroups() {
    return this.http.get(environment.baseUrl + `/api/v2/admin/notifications/groups`, this.header);
  }

  get client(): string {
    const storage = sessionStorage.getItem('loggedUser');

    if (!storage) {
      return '';
    }

    const loggedUser = JSON.parse(storage);

    return loggedUser.data.client_id;
  }

  get header(): { headers: HttpHeaders } {
    const token = sessionStorage.getItem('token');
    const headerOptions = {
      Authorization: token,
      Accept: 'application/json'
    };
    return { headers: new HttpHeaders(headerOptions) };
  }

}
