import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { StructureModule } from './structure/structure.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { MaterialDesignModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';
import { SortablejsModule } from 'ngx-sortablejs';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { RedirectComponent } from './redirect/redirect.component';
import { HomeComponent } from './home/home.component';
import { EmailEditorModule } from 'angular-email-editor';
import { Ng2IziToastModule } from 'ng2-izitoast';


@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    StructureModule,
    HttpClientModule,
    EmailEditorModule,
    ColorPickerModule,
    MaterialDesignModule,
    BrowserAnimationsModule,
    QuillModule.forRoot(),
    SortablejsModule,
    Ng2IziToastModule,
    RouterModule.forRoot([
      {
        path: 'redirect',
        component: RedirectComponent
      },
      {
        path: ':id',
        component: HomeComponent
      },
      {
        path: '',
        component: HomeComponent
      }
    ])
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
