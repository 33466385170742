import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-modal-email-marketing',
  templateUrl: './modal-email-marketing.component.html',
  styleUrls: ['./modal-email-marketing.component.scss']
})
export class ModalEmailMarketingComponent implements OnInit {

  public trails;
  constructor(
    public dialogRef: MatDialogRef<ModalEmailMarketingComponent>,
    @Inject(MAT_DIALOG_DATA) public entity,
    public service: DataService,
    public iziToast: Ng2IzitoastService
  ) { }

  ngOnInit() {
    console.log('[ModalEmailMarketingComponent] ->', this.entity);
  }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    console.log(this.entity);
    this.service.saveNotificationEmailMarketing(this.entity).subscribe(response => {
      this.dialogRef.close();
      this.iziToast.show({
        title: 'Pronto',
        message: 'Você receberá os seus testes dentro de alguns segundos.',
      })
    });
  }

}
