import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService } from './@core/services/base.service';
import { DataService } from './@core/services/data.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [DataService]
})

export class AppComponent {


}
