import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from 'src/app/@core/services/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-configurations',
  templateUrl: './modal-configurations.component.html',
  styleUrls: ['./modal-configurations.component.scss'],
  providers: [DataService]
})
export class ModalConfigurationsComponent implements OnInit {

  public lastAccess: any[] = ['15', '30', '60', '90+'];
  public groups;

  constructor(
    public dialogRef: MatDialogRef<ModalConfigurationsComponent>,
    @Inject(MAT_DIALOG_DATA) public entity,
    public service: DataService
  ) { }

  ngOnInit() {
    this.getGroups();
    console.log('Entity ->', this.entity);
  }

  close(): void {
    this.dialogRef.close();
  }

  getGroups() {
    this.service.getGroups().subscribe((response: any) => {
      this.groups = response.data;
    })
  }

  save() {
    this.dialogRef.close(this.entity);
  }

 

}
