import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TopMenuComponent } from './top-menu/top-menu.component';
import { FooterComponent } from './footer/footer.component';
import { ModalConfigurationsComponent } from './top-menu/modal-configurations/modal-configurations.component';
import { MaterialDesignModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { ModalEnvioTestesComponent } from './top-menu/modal-envio-testes/modal-envio-testes.component';
import { MatChipsModule } from '@angular/material';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { ModalEmailMarketingComponent } from './top-menu/modal-email-marketing/modal-email-marketing.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialDesignModule,
    FormsModule,
    MatChipsModule,
    Ng2IziToastModule
  ],
  declarations: [
    TopMenuComponent,
    FooterComponent,
    ModalConfigurationsComponent,
    ModalEnvioTestesComponent,
    ModalEmailMarketingComponent
  ],
  exports: [
    TopMenuComponent,
    FooterComponent
  ],
  entryComponents:[
    ModalConfigurationsComponent,
    ModalEnvioTestesComponent,
    ModalEmailMarketingComponent
  ]
})
export class StructureModule { }
